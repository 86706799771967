import React from "react"
import PropTypes from "prop-types"
import { Location } from "@reach/router"

import CookieConsent from 'react-cookie-consent';

import Footer from "./footer/footer-container"
import Navbar from "./navbar/navbar-container"

import "./layout.css"

const Layout = ({ children }) => (
  <Location>
    {({ location }) => (
      <div className="layout">
        <CookieConsent
          location="bottom"
          buttonText="Akzeptieren"
          declineButtonText="Ablehnen"
          enableDeclineButton
          cookieName="gatsby-gdpr-google-analytics"
          expires={150}
        >
          Diese Website verwendet Cookies – nähere Informationen dazu und zu Ihren Rechten als Benutzer finden Sie in unserer Datenschutzerklärung am Ende der Seite. Klicken Sie auf „Akzeptieren“, um Cookies zu akzeptieren und direkt unsere Website besuchen zu können.
        </CookieConsent>
        <Navbar location={location} />
        <noscript className="noscript-warning">
          This site relies on JavaScript which is currently disabled in your
          browser. Some functionality will be missing as a result.
        </noscript>
        <main>{children}</main>
        <Footer />
      </div>
    )}
  </Location>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
