import PropTypes from "prop-types"
import React from "react"

import Links from "./links"
import Link from "../../link/link"

import "./footer.css"

const Footer = ({ author, links }) => (
  <footer className="footer">
    {links.length > 0 && <Links links={links} />}
    <small>
      © <Link to={author.link}>{author.text}</Link> {new Date().getFullYear()}
    </small>
  </footer>
)

Footer.propTypes = {
  author: PropTypes.shape({
    link: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.arrayOf(PropTypes.string),
      link: PropTypes.string,
      text: PropTypes.string,
    })
  ).isRequired,
}

export default Footer
