import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBook, faStamp, faUsers } from "@fortawesome/free-solid-svg-icons"

import Link from "../../../link/link"

const getLinks = links => {
  const linkComponents = {
    partner: (
      <Link key="partner" to="/partner">
        <FontAwesomeIcon icon={faUsers} size="1x" />
        Partner
      </Link>
    ),
    // workshop: (
    //   <Link key="workshop" to="/workshops">
    //     <FontAwesomeIcon icon={faBook} />
    //     Termine
    //   </Link>
    // ),
    impressum: (
      <Link key="impressum" to="/impressum">
        <FontAwesomeIcon icon={faStamp} size="1x" />
        Impressum
      </Link>
    ),
    // publications: (
    //   <Link key="publications" to="/publications">
    //     <FontAwesomeIcon icon={faBook} />
    //     Publications
    //   </Link>
    // ),
  }

  return <>{links.map(link => linkComponents[link])}</>
}

export default getLinks
