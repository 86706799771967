import { useStaticQuery, graphql } from "gatsby"

const useLinks = () => {
  const query = useStaticQuery(
    graphql`
      query {
        partner: allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/partner/" } }
        ) {
          edges {
            node {
              frontmatter {
                title
              }
            }
          }
        }
        workshop: allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/workshop/" } }
        ) {
          edges {
            node {
              frontmatter {
                name
              }
            }
          }
        }
        impressum: markdownRemark(fileAbsolutePath: { regex: "/impressum.md/" }) {
          html
        }
        publications(list: { elemMatch: { title: { regex: "/.*/" } } }) {
          list {
            title
          }
        }
      }
    `
  )

  const links = []

  if (query.partner.edges.length > 0) {
    links.push("partner")
  }
  if (query.workshop.edges.length > 0) {
    links.push("workshop")
  }
  if (query.impressum) {
    links.push("impressum")
  }
  if (query.publications) {
    links.push("publications")
  }

  return links
}

export default useLinks
