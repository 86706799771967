import PropTypes from "prop-types"
import React from "react"

import Link from "../../link/link"
import Logo from "../../../images/logo.inline.svg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Links = ({ links }) => (
  <div className="footer__links">
    <Link aria-label="Home" className="footer__icon" nav to="/">
      <div className="footer__icon-svg-container">
        <Logo />
      </div>
    </Link>
    <ul>
      {links.map(link => (
        <li key={link.text}>
          <Link aria-label={link.text} className="footer__icon" to={link.link}>
            {
              link.icon ?
                (
                  <div className="footer__icon-svg-container">
                    <FontAwesomeIcon icon={link.icon} size="3x" />
                  </div>
                )
                :
                (
                  link.text
                )
            }
          </Link>
        </li>
      ))}
    </ul>
  </div>
)

Links.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.array,
      link: PropTypes.string,
      text: PropTypes.string,
    })
  ).isRequired,
}

export default Links
